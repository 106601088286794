import { RiAdvertisementFill } from "react-icons/ri";
import { FaList } from "react-icons/fa";
import { getAdvertisers, getSubCategories, getProducts } from "api";
export const API_URL = "https://dev.deep.ad";
export const HEADER_COLOR = "#131313";
export const HEADER_BANNER = "";
export const POST_COUNT_PER_PAGE = 50;
export const MAX_FILE_UPLOAD_SIZE = 1024 * 1024 * 500; // 500MB
export const TOKEN_EXPIRATION = 1000 * 60 * 60 * 24 * 7; // 1 week
export const ACCEPT_MEDIA_FILE_TYPES = [
  { name: "mp3", type: "audio" },
  { name: "wav", type: "audio" },
  { name: "aif", type: "audio" },
  { name: "m3u", type: "audio" },
  { name: "ogg", type: "audio" },
  { name: "vorb", type: "audio" },
  { name: "aac", type: "audio" },
  { name: "wma", type: "audio" },
  { name: "3gp", type: "audio" },
  { name: "aa", type: "audio" },
  { name: "aax", type: "audio" },
  { name: "flac", type: "audio" },
  { name: "png", type: "image" },
  { name: "jpeg", type: "image" },
  { name: "jpg", type: "image" },
  { name: "bmp", type: "image" },
  { name: "gif", type: "image" },
  { name: "tiff", type: "image" },
  { name: "heic", type: "image" },
  { name: "raw", type: "image" },
  { name: "aeif", type: "image" },
  { name: "mp4", type: "video" },
  { name: "mpg", type: "video" },
  { name: "mkv", type: "video" },
  { name: "mpegts", type: "video" },
  { name: "ts", type: "video" },
  { name: "apng", type: "video" },
  { name: "gif", type: "video" },
  { name: "webp", type: "video" },
  { name: "heif", type: "video" },
  { name: "webm", type: "video" },
  { name: "flv", type: "video" },
  { name: "gifv", type: "video" },
  { name: "avi", type: "video" },
  { name: "wmv", type: "video" },
  { name: "asf", type: "video" },
  { name: "m4p", type: "video" },
  { name: "mts", type: "video" },
  { name: "m2ts", type: "video" },
  { name: "ts", type: "video" },
  { name: "mov", type: "video" },
  { name: "m4v", type: "video" },
  { name: "mpg", type: "video" },
  { name: "mp2", type: "video" },
  { name: "mpeg", type: "video" },
  { name: "mpv", type: "video" },
  { name: "mxf", type: "video" },
  { name: "pdf", type: "text" },
  { name: "eps", type: "text" },
  { name: "ps", type: "text" },
  { name: "csv", type: "file" },
  { name: "tsv", type: "file" },
  { name: "tab", type: "file" },
  { name: "xls", type: "file" },
  { name: "ods", type: "file" },
  { name: "xlsx", type: "file" },
  { name: "txt", type: "file" },
  { name: "json", type: "file" },
  { name: "xml", type: "file" },
  { name: "rtf", type: "file" },
  // { name: "doc", type: "file" },
  { name: "docx", type: "file" },
];

export const ACCEPT_TEXT_FILES = [
  { name: "csv", type: "file" },
  { name: "tsv", type: "file" },
  { name: "tab", type: "file" },
  { name: "xls", type: "file" },
  { name: "ods", type: "file" },
  { name: "xlsx", type: "file" },
  { name: "txt", type: "file" },
  { name: "json", type: "file" },
  { name: "xml", type: "file" },
  { name: "rtf", type: "file" },
  // { name: "doc", type: "file" },
  { name: "docx", type: "file" },
];

export const TAG_PREFIXES = [
  "global.exception",
  "global.category",
  "global.brand",
  "global.feature",
  "global.medium",
  "global.confirmation",
  "target",
  "class",
];

export const DURATION_MIN = 1;
export const DURATION_MAX = 120;

export const SPECIAL_DATES = [
  "yesterday",
  "yesterweek",
  "yestermonth",
  "yesterquater",
];

export const DETAIL_TABS = [
  {
    label: "INFORMATION",
    icon: <FaList size={20} />,
  },
  {
    label: "REGIONS OF INTEREST",
    icon: <RiAdvertisementFill size={20} />,
  },
];
//TODO: add these back in when we have them
// {
//   label: "TIMELINE",
//   icon: <RiTimerFill size={20} />,
// },
// {
//   label: "PIXEL TIME",
//   icon: <FaChartLine size={20} />,
// },

export const META_DATA_FIELDS = [
  {
    id: "categories",
    title: "Industry",
    isArray: true,
    values: [],
  },
  {
    id: "category_group",
    title: "Category Group",
    values: [],
  },
  {
    id: "category",
    title: "Category",
    values: [],
  },
  {
    id: "sub_category",
    title: "Sub-Category",
    dataId: "subCategories",
    values: [],
    editable: true,
    searchFunc: getSubCategories,
  },
  {
    id: "big_brand",
    title: "Advertiser",
    values: [],
    dataId: "advertisers",
    editable: true,
    searchFunc: getAdvertisers,
  },
  {
    id: "big_product",
    title: "Product",
    values: [],
    dataId: "big_product",
    editable: true,
    searchFunc: getProducts,
  },
  {
    id: "platform",
    title: "Platform",
    values: [],
  },
  {
    id: "network",
    title: "Network",
    values: [],
  },
  {
    id: "socials",
    title: "Socials",
    values: [],
    isArray: true,
  },
  {
    id: "domains",
    title: "Domains",
    values: [],
    isArray: true,
  },
  {
    id: "urls",
    title: "URLs",
    values: [],
    isArray: true,
  },
  {
    id: "retailer",
    title: "Retailer",
    values: [],
  },
];

export const PIN_FIELDS = [
  {
    id: "big_brand",
    title: "Advertiser",
    values: [],
  },
  {
    id: "sub_category",
    title: "Sub-Category",
    values: [],
  },
];
