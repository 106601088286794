import React from "react";
import styled from "styled-components";
import { FaTag } from "react-icons/fa";
import { AiOutlineClose } from "react-icons/ai";

export const Tag = ({
  value,
  pin,
  onRemove = () => {},
  isRemovable = false,
  className,
}) => {

  return (
    <ItemContainer className={className} pin={pin}>
      <FaTag color="white" className="my-auto" size={12} />
      <ItemContent className="ml-2 my-auto mr-3">
        {value.replace(/_/g, " ")}
      </ItemContent>
      {isRemovable && (
        <ItemClose className="my-auto" onClick={onRemove}>
          <AiOutlineClose size={14} color="white" className="my-auto" />
        </ItemClose>
      )}
    </ItemContainer>
  );
};

const ItemContent = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 500px;
`;

const ItemContainer = styled.div`
  background: ${(props) => (props.pine ? "" : props.theme.palette.gray)};
  height: 24px;
  padding: 0px 6px;
  display: flex;
  font-size: 14px;
  line-height: 14px;
  color: white;
  min-width: 75px;
  margin: 3px 0;
  width: fit-content;
  svg {
    min-width: 12px;
  }
`;
const ItemClose = styled.div`
  cursor: pointer;
  transition: 300ms ease all;
  &:hover {
    opacity: 0.7;
  }
`;
