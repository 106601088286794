/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useAuthContext, useDataContext } from "contexts";
import { RiPushpin2Fill } from "react-icons/ri";
import { Button, AutoComplete } from "components";
import { useInput } from "hooks";
import { updateCertMetaField } from "api";
import { Tag } from "./Tag";

export const SummaryItem = ({
  title,
  post,
  values = [],
  pin = false,
  dataId,
  editable,
  searchFunc,
  certId,
  id,
  isArray,
  onUpdate = () => {},
  onSetValue = () => {},
}) => {
  const { userMode } = useAuthContext();
  const data = useDataContext();
  const [isAdding, setIsAdding] = useState(false);
  const [isRemoving, setIsRemoving] = useState(false);
  const [isClearing, setIsClearing] = useState(false);
  // const [searchData, setSearchData] = useState("");
  const field = useInput();

  // const handleSearchChange = async (data) => {
  //   setSearchData(data);
  // };
  
  const handleAdd = async () => {
    let value = field.value;

    if (id === "sub_category") {
      value = value.replace(/\s/g, "_");
    }

    field.setValue("");
    setIsAdding(true);
    
    //console.log('value', value);
    await updateCertMetaField(certId, id, value);
    onUpdate();
    setIsAdding(false);
  };

  const handleClear = async () => {
    setIsClearing(true);
    field.setValue("");
    await updateCertMetaField(certId, id, "");
    onUpdate();
    setIsClearing(false);
  };

  const handleRemove = async (params) => {
    setIsRemoving(true);
    await updateCertMetaField(certId, id, '');
    onUpdate();
    setIsRemoving(false);
  };

  useEffect(() => {
    onSetValue(id, values.length > 0 ? values[0] : "");
    return () => {};
  }, [values]);

  return (
    <Container pin={pin}>
      <Label pin={pin}>{title}:</Label>
      <Values>
        {userMode !== "normal" && editable && (
          <div className="d-flex mb-2">
            <AutoComplete
              className="flex-1"
              data={data ? data[dataId] : []}
              width="100%"
              func={searchFunc}
              optional_data={[post?.cert.meta.sub_category, post?.cert.meta.big_brand]}
              {...field}
              // onSelectChange={(data) => handleSearchChange(data)}
            />
            <Button
              size="small"
              buttonTheme="dark"
              onClick={handleAdd}
              className="ml-2"
              width="80px"
              disabled={!field.value || isRemoving || isAdding || isClearing}
              isLoading={isAdding}
            >
              Add
            </Button>
            <Button
              size="small"
              buttonTheme="dark"
              onClick={handleClear}
              className="ml-2"
              width="100px"
              isLoading={isClearing}
              disabled={
                values.length === 0 || isClearing || isAdding || isRemoving
              }
            >
              Clear All
            </Button>
          </div>
        )}
        { values.map((value, index) => (
          <Tag
            pin={pin}
            value={value?.name ? value?.name : value}
            key={`${index}key`}
            isRemovable={userMode !== "normal" && editable}
            onRemove={() => handleRemove(value)}
          />
        ))}
      </Values>
      <Pin className="ml-auto">
        <RiPushpin2Fill color="white" size={16} />
      </Pin>
    </Container>
  );
};

const Container = styled.div`
  margin: 1px 0;
  padding: 12px 16px;
  display: flex;

  background: ${(props) => (props.pin ? "#F9F9BD24" : "")};
  &:nth-child(2n) {
    background: ${(props) =>
      props.pin ? "#F9F9BD24" : props.theme.palette.items};
  }
`;
const Label = styled.div`
  font-size: 16px;
  text-transform: uppercase;
  width: 200px;
  color: ${(props) =>
    props.pin
      ? props.theme.palette.accentOutline
      : props.theme.palette.primaryHover};
`;
const Values = styled.div`
  width: 600px;
  display: flex;
  flex-direction: column;
`;

const Pin = styled.div``;
