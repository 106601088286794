import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { MainLayout } from "layouts";
import { getPostApi } from "api";
import { useParams, navigate } from "@reach/router";
import { usePostContext } from "contexts";
import { useInput } from "hooks";
import { META_DATA_FIELDS } from "config";
import { capitalize, cloneDeep } from "lodash";
// import { usePostContext, useAuthContext } from "contexts";
// import { sumBy } from "lodash";
// import ColorScale from "color-scales";
// import { Warning, ScrollView, SearchBar } from "components";
import { ScrollView, SearchBar } from "components";
import { useLocation } from "@reach/router";
import { last } from "lodash";
// import { Toolbar, Section, Summary, Tab } from "./components";
import { TabBar } from "./components";
import { getAllROI, thumbnailPostApi, debugPostApi } from "api";
import { DETAIL_TABS } from "config";
import { Information, RegionOfInterest /*PixelTime, Timeline*/ } from "./tabs";
import { useQuery } from "react-query";
import { roiApi } from "api";
import {
  // EventsTimeline,
  // ValuesPanel,
  // Stats,
  // TextVisual,
  // TextAudio,
  // RegionInterest,
  // WebSection,
  // CapturePane,
  PlayerFragment,
  MetadataSection,
} from "./fragments";

// const tabData = ["Share of Voice", "Pixel Time of Brands"];

export const DetailPage = (props) => {
  const { queryPosts, setCurrentPost } = usePostContext();
  // const [postSource, setPostSource] = useState("raw");
  // const { userInfo } = useAuthContext();
  // const currentTab = useInput(tabData[0]);
  // const [webData, setWebData] = useState([]);
  // const [shareVoices, setShareVoices] = useState([]);
  // const [pixelTime, setPixelTime] = useState([]);
  const params = useParams();
  const [enableCapture, setEnableCapture] = useState(false);
  const currentTab = useInput(DETAIL_TABS[0].label);
  const [roiData, setRoiData] = useState([]);
  const location = useLocation();
  const [isCaptureUploading, setIsCaptureUploading] = useState(false);
  const [advertiser, setAdvertiser] = useState("");
  const [subCategory, setSubCategory] = useState("");
  const [bigProduct, setBigProduct] = useState("");

  const [isThumbnailing, setIsThumbnailing] = useState(false);
  const [isDebugging, setIsDebugging] = useState(false);

  const { fieldPinData, setToolbarState } = usePostContext();
  const [metaData, setMetaData] = useState(META_DATA_FIELDS);
  const [summary, setSummary] = useState("");

  const {
    data,
    isLoading = true,
    refetch,
  } = useQuery(["post-detail", params.id], () => getPostApi(params.id), {
    keepPreviousData: true,
    cacheTime: 1000 * 60 * 60 * 24,
    enabled: !!params.id,
    onSuccess: (data) => {
      setCurrentPost(data.result);
    },
  });
  const post = data?.result;
  const init = async () => {
    setToolbarState("move");
    try {
      const res = await getAllROI(last(location.pathname.split("/")));
      console.log("roi", res.payload);
      if (res.success) {
        setRoiData(res.payload);
      } else {
        setRoiData([]);
      }
    } catch (error) {
      console.log("detail page error", error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  useEffect(() => {
    init();
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.id]);

  useEffect(() => {
    if (post && post?.cert) {
      let temp = post?.cert?.meta?.objects?.matching_urls || [];
      temp.push(...(post?.cert?.meta?.objects?.visually_similar_urls || []));
      temp.push(...(post?.cert?.meta?.objects?.webs || []));
      // setWebData(temp);
      if (
        post?.cert?.meta?.super_brands &&
        post?.cert?.meta?.super_brands.length > 0
      ) {
        // let colorScale = new ColorScale(0, 100, ["#FFFF70", "#927F03"]);
        // let brands = post.cert.meta.super_brands.map((brand, index) => {
        //   return {
        //     value: brand.fpp / sumBy(post.cert.meta.super_brands, (d) => d.fpp),
        //     name: brand.brand,
        //     color: colorScale
        //       .getColor((100 / post.cert.meta.super_brands.length) * index)
        //       .toHexString(),
        //   };
        // });
        // setShareVoices(brands);
        // const pixels = [
        //   ...post.cert.meta.super_brands.map((brand, index) => {
        //     return {
        //       value: brand.fpp,
        //       name: brand.brand,
        //     };
        //   }),
        //   {
        //     name: "Unbranded",
        //     value: 1 - sumBy(post.cert.meta.super_brands, (d) => d.fpp),
        //   },
        // ];
        // setPixelTime(
        //   pixels.map((brand, index) => {
        //     return {
        //       value: brand.value,
        //       name: brand.name,
        //       color: colorScale
        //         .getColor((100 / brands.length) * index)
        //         .toHexString(),
        //     };
        //   })
        // );
      }
    }
    return () => {};
  }, [post]);
  useEffect(() => {
    if (post?.cert) {
      let str = "";
      if (post?.cert?.meta?.description?.opener) {
        str += capitalize(post?.cert?.meta?.description?.opener || "");
      }
      if (post?.cert?.meta?.stt?.sentence) {
        str += ` ${post?.cert?.meta?.stt?.sentence || ""}`;
      }
      if (post?.cert?.meta?.faces?.tone) {
        str += ` with a ${post?.cert?.meta?.faces?.tone} mood`;
        if (post?.cert?.meta?.sentiment?.mood) {
          str += ` and ${post?.cert?.meta?.sentiment?.mood} tone.`;
        } else {
          str += ".";
        }
      } else if (post?.cert?.meta?.sentiment?.mood) {
        str += ` with a ${post?.cert?.meta?.sentiment?.mood} tone.`;
      }
      if (post?.cert?.meta?.description?.closer) {
        str += ` ${capitalize(post?.cert?.meta?.description?.closer)}`;
      }
      setSummary(capitalize(str.trim()));
      let tempMeta = metaData.map((item) => {
        let temp = { ...item };
        if (post?.cert?.meta) {
          if (post?.cert?.meta[item.id]) {
            console.log('post?.cert?.meta', post?.cert?.meta);
            if (item.isArray || item.id === 'super_products' || item.id === 'super_brands') {
              temp.values = post?.cert?.meta[item.id];
            } else {
              temp.values = [post?.cert?.meta[item.id]];
            }
          } else {
            temp.values = [];
          }

        }
        return temp;
      });
      console.log('tempMeta', tempMeta);
      setMetaData(updateOrderMetaByPin(cloneDeep(tempMeta)));
    }
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [post, fieldPinData]);
  // const handleEventClick = (frame) => {
  //   if (player.current) {
  //     player.current.seek(frame / 30);
  //   }
  // };

  const updateOrderMetaByPin = (params) => {
    let temp = [];
    fieldPinData.forEach((field) => {
      temp.push(params.find((item) => item.id === field));
    });
    temp = [
      ...temp,
      ...params.filter((item) => !fieldPinData.includes(item.id)),
    ];
    return temp;
  };
  const handleCrop = async (params) => {
    currentTab.setValue(DETAIL_TABS[1]?.label);
    setEnableCapture(false);
    setIsCaptureUploading(true);
    console.log("crop", params);
    const res = await roiApi(last(location.pathname.split("/")), params);
    console.log("roi", res.payload);
    setRoiData(res.payload);
    setIsCaptureUploading(false);
  };

  const handleRemove = (params) => {
    setRoiData(params);
  };

  const handleDebug = async () => {
    setIsDebugging(true);
    await debugPostApi(params.id);
    refetch();
    setIsDebugging(false);
  };

  const handleThumbnail = async () => {
    setIsThumbnailing(true);
    await thumbnailPostApi(params.id);
    refetch();
    setIsThumbnailing(false);
  };

  const handleSearch = async (params) => {
    navigate("/home");
    await queryPosts(params);
  };

  const handleInfoUpdate = (id, value) => {
    console.log("info update", id, value);
    switch (id) {
      case "big_brand":
        setAdvertiser(value);
        break;
      case "sub_category":
        setSubCategory(value);
        break;
      case "big_product":
        setBigProduct(value);
        break;
      default:
        break;
    }
  };

  return (
    <MainLayout>
      <SearchBar
        isLoaded={!isLoading}
        onSearch={handleSearch}
        onDebug={handleDebug}
        onThumbnail={handleThumbnail}
        isDebugging={isDebugging}
        isThumbnailing={isThumbnailing}
        advertiser={advertiser}
        subCategory={subCategory}
        bigProduct={bigProduct}
        post={post}
      />

      <MainContent>
        {data?.success === false ? (
          <Error>{data.error}</Error>
        ) : (
          <>
            <LeftSection>
              <PlayerFragment
                postId={params.id}
                isLoading={isLoading}
                enableCapture={enableCapture}
                onCrop={handleCrop}
                creditSize={post?.credit_size}
              />
              <MetadataSection post={post} isLoading={isLoading} />
            </LeftSection>
            <RightSection>
              <TabBar {...currentTab} />
              <InnerContent>
                {currentTab.value === DETAIL_TABS[0].label ? (
                  <Information
                    post={post}
                    isLoading={isLoading}
                    onUpdate={() => refetch()}
                    onSetValue={handleInfoUpdate}
                    metaData={metaData}
                    summary={summary}
                  />
                ) : currentTab.value === DETAIL_TABS[1].label ? (
                  <RegionOfInterest
                    data={roiData}
                    metaData={metaData}
                    isUploading={isCaptureUploading}
                    onRemove={handleRemove}
                  />
                ) : // ) : currentTab.value === DETAIL_TABS[2].label ? (
                //   <Timeline />
                // ) : currentTab.value === DETAIL_TABS[3].label ? (
                //   <PixelTime />
                null}
              </InnerContent>
            </RightSection>
          </>
        )}
      </MainContent>
    </MainLayout>
  );
};

const MainContent = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  overflow: auto;
  background: ${(props) => props.theme.palette.canvas};
  /* border-top: 3px solid ${(props) => props.theme.palette.backgrounds}; */
`;

const InnerContent = styled(ScrollView)`
  display: flex;
  flex-direction: column;
`;

const LeftSection = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 4px;
  width: 840px;
  min-width: 840px;
`;

const RightSection = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

const Error = styled.div`
  text-align: center;
  color: white;
  font-size: 40px;
  width: 100%;
  padding-top: 200px;
`;
