/* eslint-disable react-hooks/exhaustive-deps */
import { useRef, useState, useEffect } from "react";
import classNames from "classnames";
import { useDebounce } from "use-debounce";
import { useOutsideClick } from "hooks";
import styled from "styled-components";
import { useQuery } from "react-query";
import { AiFillCaretDown, AiOutlineClose } from "react-icons/ai";
import ClipLoader from "react-spinners/ClipLoader";
import { usePostContext } from "contexts";
import "./style.scss";

export const AutoComplete = ({
  value,
  onChange,
  label,
  className,
  optional_data = [],
  backgroundColor = "#eee",
  placeholder = "Search or type here",
  width = 160,
  id = "",
  func = () => {},
  onSelectId = () => {},
  onSelectChange = () => {}
}) => {
  const menuRef = useRef(null);
  const [search, setSearch] = useState("");
  const [showResults, setShowResults] = useState(false);
  const [debouncedValue] = useDebounce(search, 500);
  const { post } = usePostContext();
  useOutsideClick(menuRef, clickOutsideMenu);
  function clickOutsideMenu() {
    setShowResults(false);
  }
  const handleChange = (params) => {
    if (params !== value) {
      setSearch("");
      onChange(params.name, params);

      console.log('params', params);
      onSelectChange(params);
      onSelectId(params.id);
    }
    setShowResults(false);
  };

  const { data, isLoading, isFetching } = useQuery(
    [debouncedValue],
    () => func(debouncedValue, post?.user_id, optional_data),
    {
      keepPreviousData: true,
      enabled: debouncedValue.length > 0,
    }
  );

  const [focused, setFocused] = useState(false);

  const handleBlur = (event) => {
    setFocused(false);
  };

  const handleClear = () => {
    onChange("");
    setSearch("");
  };

  useEffect(() => {
    if (debouncedValue) {
      setShowResults(true);
    } else {
      setShowResults(false);
    }
    return () => {};
  }, [debouncedValue]);

  return (
    <div className={classNames("d-flex", className)}>
      {label && <span className="select-label my-auto">{label}</span>}
      <div className="auto-complete" style={{ width }}>
        <div className="menu-button" style={{ width }}>
          {value ? (
            <>
              <span className="mr-auto">{value}</span>
              <AiOutlineClose
                color="white"
                className="menu-button__drop-down"
                alt="clear"
                onClick={() => handleClear()}
              />
            </>
          ) : (
            <div className="d-flex w-100">
              <StyledInput
                type="text"
                aria-labelledby={"auto complete"}
                onFocus={() => setFocused(true)}
                onBlur={handleBlur}
                aria-describedby={"auto complete"}
                value={search}
                placeholder={placeholder}
                onChange={(e) => {
                  setSearch(e.target.value);
                }}
              />

              <AiFillCaretDown
                color="white"
                className="menu-button__drop-down"
                alt="drop-down"
              />
            </div>
          )}

          <Underline focused={focused} />
        </div>
        {showResults && (
          <div ref={menuRef} className="menu-dropdown">
            {isLoading || isFetching ? (
              <div className="d-flex justify-content-center py-4">
                <ClipLoader size={32} color="#006280" />
              </div>
            ) : data?.results && data?.results.length > 0 ? (
              data.results.map((item, index) => {
                return (
                  <div
                    className={classNames(
                      "menu-item",
                      item.name === value ? "active" : ""
                    )}
                    key={`key${index}`}
                    onClick={() => handleChange(item)}
                    style={{ width }}
                  >
                    {item.name}
                  </div>
                );
              })
            ) : (
              <div className="my-3 d-flex justify-content-center">
                No results
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

const StyledInput = styled.input`
  background: transparent;
  color: white;
  border: none;
  height: 32px;
  line-height: 32px;
  width: calc(100% - 24px);
  font-size: 16px;
  outline: none;
  &:focus,
  &:hover {
    border: none;
    outline: none;
  }
`;

const Underline = styled.div`
  background: ${(props) => props.theme.palette.primary};
  width: 100%;
  height: 2px;
  position: absolute;
  bottom: 0;
  transform-origin: left;
  transition: transform 500ms ease;
  transform: ${(props) => (props.focused ? "" : "scale3d(0, 1, 1)")};
`;
