import { API } from "utils";
import { last } from "lodash";

export const getAdvertisers = async (search = "", user_id) => {
  try {
    const response = await API().get(
      `api/get_advertisers?search=${search}&post_id=${last(
        window.location.pathname.split("/")
      )}${user_id ? `&user_id=${user_id}` : ""}`
    );
    return response.data;
  } catch (e) {
    return { success: false, payload: {}, error: e };
  }
};

export const getSubCategories = async (search = "", user_id) => {
  try {
    const response = await API().get(
      `api/get_sub_categories?search=${search}${
        user_id ? `&user_id=${user_id}` : ""
      }`
    );
    return response.data;
  } catch (e) {
    return { success: false, payload: {}, error: e };
  }
};

export const getProducts = async (search = "", user_id, optional_data) => {

  //TODO: Scaffolding for actual endpoint
  // return { success: true, results: [
  //   {
  //     "id": "global.brand.coca_cola",
  //     "brand": "Coca Cola",
  //     "description": "Soft drink",
  //     "detected": "summary",
  //     "exact": true,
  //     "name": "Coca Cola",
  //     "pixels": 0,
  //     "snippet": "",
  //     "tag": "global.brand.coca_cola",
  //     "url": ""
  //   },
  //   {
  //       "id": "global.brand.pepsi_zero_sugar",
  //       "brand": "Pepsi Zero Sugar",
  //       "description": "Soft drink",
  //       "detected": "summary",
  //       "exact": true,
  //       "name": "Pepsi Zero Sugar",
  //       "pixels": 0,
  //       "snippet": " CD 12R ( 355 PEPSI MOCKTAIL inspiration PEPSI on the rocks ded ZERO SUGAR PEPSI on the rocks pepsi ZERO SUGAR pepsi ZERO SUGAR ",
  //       "tag": "global.brand.pepsi_zero_sugar",
  //       "url": ""
  //   },
  // ]};

  //console.log('optional_data', optional_data);

  let class_name = ''
  if(optional_data[0]) {
    class_name = optional_data[0].replace(/&/g, "%26")
  }


  try {
    const response = await API().get(
      `api/get_products?search=${search}${
        user_id ? `&user_id=${user_id}` : ""
      }${
        optional_data[0] ? `&class_name=${class_name}` : ""
      }${
        optional_data[1] ? `&target_name=${optional_data[1]}` : ""
      }`
    );
    return response.data;
  } catch (e) {
    return { success: false, payload: {}, error: e };
  }
};


export const updateCertMeta = async (cert_id, meta) => {
  try {
    const response = await API().post(`api/update_cert_meta`, {
      cert_id,
      meta,
    });
    return response.data;
  } catch (e) {
    return { success: false, payload: {}, error: e };
  }
};

export const updateCertMetaField = async (cert_id, field, value) => {
  try {
    const response = await API().post(`api/update_cert_meta_field`, {
      cert_id,
      field,
      value,
    });
    return response.data;
  } catch (e) {
    return { success: false, payload: {}, error: e };
  }
};
